<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="bLocalLoading"
    :options="pagination"
    :server-items-length="serverItemsLength"
    class="dt-listing"
    dense
    disable-sort
    hide-default-footer
    @update:page="paginate"
  >
    <template #top>
      <div class="text-h5 ml-2 mt-2 d-flex">
        <currency-preview :item="obCurrency" class="mr-4" />
        {{ $t("currency.history") }}
      </div>
    </template>

    <template #footer>
      <footer-table
        v-model="pagination.page"
        :lastPage="serverLastPage"
        :total="serverItemsLength"
      />
    </template>

    <template #[`item.rated_at`]="{ item }">
      {{ $dayjs(item.rated_at).format("L") }}
    </template>

    <template #[`item.rate`]="{ item }">
      <price-field
        v-if="isAdmin && !item.company_id"
        hide-label
        :value="item.rate"
        :dense="true"
        :outlined="false"
        :solo="true"
        :full-width="false"
        @save="onUpdateRate($event, item)"
      >
        <template v-if="loading === item.id" #append>
          <v-progress-circular indeterminate :size="12" :width="2" />
        </template>
      </price-field>
      <price-viewer v-else :currency-id="item.currency_id" :value="item.rate" />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import DataTableMixin from "@/mixins/DataTableMixin";
import PaginateMixin from "@/mixins/PaginateMixin";
import {
  CurrencyRate,
  type CurrencyRateData,
  CurrencyRates,
} from "@planetadeleste/vue-mc-gw";
import { AppModule } from "@/store/app";
import { isEmpty, set } from "lodash";
import type { DataTableHeader } from "vuetify";
import dayjs from "dayjs";
import FooterTable from "@/components/common/data-table/Footer.vue";
import { AuthModule } from "@/store/auth";
import PriceField from "@/components/form/fields/PriceField.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";

@Component({
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  components: { CurrencyPreview, PriceViewer, PriceField, FooterTable },
})
export default class CurrencyRateHistory extends Mixins(
  DataTableMixin,
  PaginateMixin
) {
  @Prop(String) readonly currency!: string;
  @Prop(Number) readonly currencyId!: number;
  @Prop(Number) readonly company!: number;
  @Prop(String) readonly dateFrom!: string;
  @Prop(String) readonly dateTo!: string;
  @Prop(String) readonly searchKey!: string;

  obCollection: CurrencyRates = new CurrencyRates();
  loading: number = 0;

  get isAdmin() {
    return AuthModule.isAdmin;
  }

  get currencies() {
    return AppModule.currencies;
  }

  get items() {
    return this.obCollection && this.obCollection.length
      ? // @ts-ignore
        this.obCollection.getModelList()
      : [];
  }

  get obCurrency() {
    const obFilter = this.currencyId
      ? { id: this.currencyId }
      : { code: this.currency };
    return isEmpty(obFilter) || !this.currencies.length
      ? undefined
      : this.currencies.find(obFilter);
  }

  @Watch("searchKey")
  async onSearch() {
    await this.onLoad();
  }

  created() {
    this.sMethod = "onLoad";
  }

  async onLoad() {
    if (
      (!this.currency && !this.currencyId) ||
      !this.currencies ||
      !this.currencies.length
    ) {
      return;
    }

    let iCurrencyId = this.currencyId;

    if (!iCurrencyId && this.obCurrency) {
      iCurrencyId = this.obCurrency.id;
    }

    this.bLocalLoading = true;

    // Init filters object
    const obFilters: Record<string, any> = { currency: iCurrencyId };

    // Filter by company or by defaults
    if (this.company) {
      set(obFilters, "company", this.company);
    } else {
      set(obFilters, "defaults", 1);
    }

    // Filter by rated_at dates
    if (this.dateFrom) {
      const arDate: string[] = [this.dateFrom];

      if (this.dateTo) {
        arDate.push(this.dateTo);
      }

      set(obFilters, "rated_at", arDate);
    }

    this.obCollection = new CurrencyRates();
    // @ts-ignore
    this.obCollection.filterBy(obFilters).page(this.currentPage);
    // @ts-ignore
    this.obCollection.limit(15);
    const obResponse = await this.obCollection.fetch();

    if (obResponse) {
      this.mapPagination(obResponse.getData());
    }

    this.bLocalLoading = false;
  }

  async onUpdateRate(fValue: number, obItem: CurrencyRateData) {
    if (!fValue || !obItem?.id) {
      return;
    }

    this.loading = obItem.id;
    const obModel = new CurrencyRate(obItem);
    obModel.set("rate", fValue);
    await obModel.save();
    this.loading = 0;
  }

  async mounted() {
    const arHeaders: DataTableHeader[] = [
      {
        text: "rate.date",
        value: "rated_at",
        class: "text-end",
        cellClass: "text-end",
      },
      { text: "rate", value: "rate", class: "text-start" },
    ];
    this.setDTHeaders(arHeaders);
    await this.onLoad();
  }
}
</script>
